import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'

import { makeProduct } from './makeProduct'
import type { AddToCartEvent } from './types/addToCartEvent'

export const makeAddToCartEvent = (
  items: Array<{ product: Product; productItem: ProductItem }>
): AddToCartEvent => {
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: items.map((item) =>
          makeProduct(item.product, item.productItem)
        ),
      },
    },
  }
}
