import classNames from 'classnames'
import { Link } from 'gatsby'
import { useEffect } from 'react'
import { ArrowRight } from 'src/components/Icons/ArrowRight'

import type { ProductSearch } from '.'
import { SuggestionItem } from './SuggestionItem'
import Portal from './Portal'
import { CardItem } from './CardItem'

type Props = {
  onClose: () => void
  isOpen: boolean
  suggestions: Array<{
    term: string
  }>
  products: ProductSearch[]
  term?: string
  resultNotFound: boolean
}

export function ModalSearch({
  isOpen,
  onClose,
  products,
  suggestions,
  term,
  resultNotFound,
}: Props) {
  useEffect(() => {
    const closeModal = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keyup', (e) => closeModal(e))
  }, [onClose])

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={classNames(
        'z-10 bg-restructure-background-primary flex-1 max-w-[846px] restructure-small-desktop:min-w-[600px] shadow-1 w-full restructure-small-desktop:h-[654px]  overflow-y-auto scrollbar-v2',
        'restructure-small-desktop:absolute restructure-small-desktop:top-[53px] restructure-small-desktop:left-1/2 restructure-small-desktop:-translate-x-1/2'
      )}
    >
      {term !== '' && resultNotFound && (
        <div className="px-md restructure-small-desktop:px-xl">
          <p className="desktop-heading-title4 text-restructure-primary  border-b border-restructure-border-primary py-xl">
            Não encontramos resultados para ”{term}”. Verifique a ortografia ou
            tente termos de pesquisa menos específicos.
          </p>
        </div>
      )}
      {suggestions && (
        <>
          <h3 className="mt-xl desktop-body-regular-text1 text-restructure-primary w-full px-md restructure-small-desktop:px-xl">
            {term !== '' && !resultNotFound
              ? 'Você pode estar procurando'
              : 'Os queridinhos da Decathlon'}
          </h3>
          <ul
            className={classNames('flex mt-md flex-wrap  pb-xxs', {
              'px-md scrollbar-v2 restructure-small-desktop:px-xl gap-md flex-nowrap restructure-small-desktop:flex-wrap overflow-x-auto restructure-small-desktop:overflow-x-visible':
                term === '' || resultNotFound,
            })}
          >
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.term}
                className={classNames('flex w-fit', {
                  'w-full': term !== '' && !resultNotFound,
                })}
              >
                <SuggestionItem
                  term={term}
                  label={suggestion.term}
                  onClick={() => onClose()}
                  type={term !== '' && !resultNotFound ? 'list' : 'suggestion'}
                />
              </li>
            ))}
          </ul>
        </>
      )}
      {products.length > 0 && (
        <ul className="flex flex-col gap-lg mt-xl px-md restructure-small-desktop:px-xl pb-xl">
          <h3 className="desktop-body-regular-text1 text-restructure-primary">
            {term !== '' && !resultNotFound
              ? 'Nossas sugestões'
              : 'Produtos em alta'}
          </h3>
          {products.map((product, idx) => (
            <li key={`${product.productId}-${idx}`}>
              <CardItem
                key={product.slug}
                imageUrl={product.imageUrl}
                name={product.name}
                slug={product.slug}
                clickUrl={product.clickUrl}
                price={product?.offer?.price}
                listPrice={product?.offer?.listPrice}
                onClick={() => onClose()}
              />
            </li>
          ))}
        </ul>
      )}
      {term && !resultNotFound && (
        <Link
          to={`/pesquisa?q=${term}&sort=score_desc&page=0`}
          className="sticky w-full text-left desktop-body-semibold-text3 text-restructure-action bottom-0 left-0 border-t border-restructure-border-primary bg-restructure-background-primary py-md px-md restructure-small-desktop:px-xl flex items-center gap-xs"
          onClick={() => onClose()}
        >
          ver todos os resultados para "{term}"
          <ArrowRight />
        </Link>
      )}
      {isOpen && (
        <Portal>
          <span
            onClick={() => onClose()}
            role="button"
            aria-hidden
            className="z-[999] hidden restructure-small-desktop:fixed restructure-small-desktop:block w-screen h-screen top-0 left-0 bg-restructure-background-primary-inverted opacity-80"
          />
        </Portal>
      )}
    </div>
  )
}
