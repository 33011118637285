import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'
import { formatCategoriesForEvent } from 'src/utils/formatted'

import type { Product as ProductEvent } from './types/common/product'

export const makeProduct = (
  product: Product,
  productItem: ProductItem,
  triggerEvent = 'página de produto'
): ProductEvent => {
  const offer = productItem.sellerDefault.sellerOffer

  return {
    brand: product.brand,
    category: product.sport,
    dimension1: product.productReference, // super modelo sap
    dimension2: productItem.referenceId ?? '', // id sku sap
    dimension12: productItem.sellerDefault.sellerId,
    dimension13: productItem.sellerDefault.sellerName,
    dimension21: formatCategoriesForEvent(product.categories[0]),
    dimension22: productItem.itemId, // id sku vtex
    dimension23: productItem.manufacturerCode, // id modelo sap (no app é item category 5)
    dimension24: triggerEvent, // origem do evento
    dimension25: 'página de produto',
    id: product.productId, // id modelo vtex
    name: product.productName,
    price: offer.price,
    quantity: 1,
    variant: `${productItem.itemId} | ${productItem.name}`,
  }
}
