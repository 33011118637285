import type { IconProps } from '../typesIcons'

export function EloPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Cartão de crédito bandeira Elo"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7712 12.5757C12.4911 13.9259 11.2749 14.9413 9.81732 14.9413C9.48289 14.9413 9.16099 14.8877 8.86064 14.7889L8.19112 16.7534C8.70207 16.9213 9.24876 17.0122 9.81732 17.0122C12.295 17.0122 14.3617 15.2856 14.8379 12.9913L12.7712 12.5757Z"
        fill="white"
      />
      <path
        d="M24.7846 8.13965V13.8197L25.8039 14.2283L25.3217 15.3486L24.3137 14.9435C24.0871 14.8484 23.9337 14.7036 23.8171 14.5401C23.7052 14.3727 23.6223 14.144 23.6223 13.8354V8.13965H24.7846ZM17.0539 12.3613C17.0792 10.7369 18.4625 9.4403 20.1411 9.46518C21.5657 9.48679 22.7462 10.4517 23.0562 11.7351L17.5461 14.0124C17.226 13.5393 17.0441 12.97 17.0539 12.3613ZM18.3147 12.589C18.3071 12.5208 18.3018 12.451 18.3038 12.3805C18.3196 11.4234 19.134 10.6598 20.1229 10.6757C20.6612 10.6826 21.1392 10.921 21.4623 11.2899L18.3147 12.589ZM21.3463 13.6435C21.0143 13.9559 20.5639 14.1462 20.0667 14.1394C19.7259 14.1339 19.4101 14.0352 19.1416 13.8708L18.4759 14.8964C18.9318 15.1751 19.4693 15.3399 20.0488 15.3486C20.8923 15.3607 21.6611 15.0403 22.2202 14.5113L21.3463 13.6435ZM29.0269 10.6756C28.8284 10.6756 28.6376 10.7067 28.4593 10.7645L28.0627 9.61525C28.3654 9.5175 28.6896 9.46441 29.0269 9.46441C30.4991 9.46441 31.7273 10.4757 32.0089 11.819L30.7811 12.061C30.6157 11.2704 29.8932 10.6756 29.0269 10.6756ZM27.0111 14.6105L27.8407 13.7033C27.4702 13.3861 27.2368 12.9228 27.2368 12.4064C27.2368 11.8906 27.4702 11.4275 27.8403 11.1105L27.0101 10.2034C26.3807 10.7424 25.9842 11.53 25.9842 12.4064C25.9842 13.2839 26.381 14.0713 27.0111 14.6105ZM29.0269 14.1378C29.8923 14.1378 30.6147 13.5435 30.7811 12.7541L32.0085 12.9972C31.7255 14.3391 30.4978 15.3489 29.0269 15.3489C28.6892 15.3489 28.3646 15.2957 28.0611 15.1975L28.4587 14.0486C28.6372 14.1063 28.8282 14.1378 29.0269 14.1378Z"
        fill="white"
      />
      <path
        d="M6.39936 15.9035L7.6372 14.3052C7.08467 13.7463 6.73593 12.9304 6.73593 12.0209C6.73593 11.1121 7.08434 10.2962 7.63672 9.73776L6.39822 8.13965C5.45935 9.08916 4.86769 10.4761 4.86769 12.0209C4.86769 13.5665 5.46021 14.954 6.39936 15.9035Z"
        fill="white"
      />
      <path
        d="M8.85969 9.25161C9.16001 9.15314 9.48129 9.09968 9.81557 9.09968C11.2745 9.09968 12.4914 10.1163 12.7706 11.4668L14.8379 11.0532C14.3636 8.75802 12.2955 7.03027 9.81557 7.03027C9.2478 7.03027 8.70128 7.12101 8.19112 7.28816L8.85969 9.25161Z"
        fill="white"
      />
    </svg>
  )
}
