import axios from 'axios'

type LogProps = {
  slug: string
  context: string
  error: string
}

export class MasterDataLogger {
  public async logRequest(log: LogProps) {
    const body = log

    const response = await axios.get(
      'https://decathlonstore.myvtex.com/api/dataentities/log/search',
      {
        params: {
          _schema: 'pdp',
          _where: `(slug='${log.slug}' AND context='${log.context}')`,
        },
      }
    )

    if (response.data.length) return

    return axios.post(
      'https://decathlonstore.myvtex.com/api/dataentities/log/documents',
      body,
      {
        params: { _schema: 'pdp' },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
}
