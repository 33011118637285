import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { useLinxUser } from './useLinxUser'

type ProductClickProps = {
  clickUrl: string
}

type Params = {
  userId?: string
  deviceId: string
  source: string
}

export const useLinxProductClick = () => {
  const { getLinxUser } = useLinxUser()
  const linxUser = getLinxUser()

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 768)
    })
  }, [])

  const sendProductClickEvent = useCallback(
    ({ clickUrl }: ProductClickProps) => {
      let params: Params = {
        deviceId: linxUser.deviceId,
        source: !isMobile ? 'desktop' : 'mobile',
      }

      if (linxUser.loggedIn) {
        params = { ...params, userId: (linxUser as { id: string }).id }
      }

      const url = clickUrl.includes('chaordic')
        ? clickUrl
        : `https://api.linximpulse.com${clickUrl}`

      return axios.get(`${url}`, { params })
    },
    [isMobile, linxUser]
  )

  return { sendProductClickEvent }
}
