import { useCallback } from 'react'

type StorageType = 'session' | 'local'

type UseStorageReturnValue = {
  storageGetItem: (key: string, type?: StorageType) => string
  storageSetItem: (key: string, value: string, type?: StorageType) => boolean
  storageRemoveItem: (key: string, type?: StorageType) => void
}

const useStorage = (): UseStorageReturnValue => {
  const storageType = (type?: StorageType): 'localStorage' | 'sessionStorage' =>
    `${type ?? 'session'}Storage`

  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')()

  const storageGetItem = useCallback(
    (key: string, type?: StorageType): string => {
      return isBrowser ? window[storageType(type)][key] : ''
    },
    [isBrowser]
  )

  const storageSetItem = useCallback(
    (key: string, value: string, type?: StorageType): boolean => {
      if (isBrowser) {
        window[storageType(type)].setItem(key, value)

        return true
      }

      return false
    },
    [isBrowser]
  )

  const storageRemoveItem = (key: string, type?: StorageType): void => {
    window[storageType(type)].removeItem(key)
  }

  return {
    storageGetItem,
    storageSetItem,
    storageRemoveItem,
  }
}

export default useStorage
