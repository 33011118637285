import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'

import { IconCloseMenu } from './utils/icons'

interface TitleSubmenuProps {
  title: string
  actionClose: (state?: boolean) => void
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TitleSubmenu = ({
  title,
  actionClose,
  setMenuIsOpen,
}: TitleSubmenuProps) => {
  return (
    <div>
      <div className="w-full px-7 pt-[18px] pb-[24px] flex items-center gap-2">
        <Button
          type="button"
          aria-label="Voltar"
          icon={
            <Icon
              name="NewChevronLeft"
              width={8}
              height={14}
              className="text-restructure-primary"
            />
          }
          iconPosition="right"
          className="!p-1"
          onClick={() => actionClose()}
        />
        <h3 className="mobile-body-regular-text3 desktop-body-regular-text3">
          {title}
        </h3>
        <button className="ml-auto" onClick={() => setMenuIsOpen(false)}>
          <IconCloseMenu />
        </button>
      </div>
    </div>
  )
}
