import type {
  Level3BannerBlock,
  PossibleThirdLevelLayout,
} from 'src/graphql/queries/menu'
import { Link } from 'gatsby'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import type { DropdownContentVariations } from '..'

export interface BannersBlockProps {
  typeThirtLevel: PossibleThirdLevelLayout
  subcategories: Level3BannerBlock[]
  titleId?: string
  menuStyles?: {
    [key: string]: string
  }
  department?: string
}

export const BannersBlockContainer = (props: DropdownContentVariations) => {
  const { subcategories = [] } = props as BannersBlockProps

  return (
    <BannersBlock
      subcategories={subcategories}
      typeThirtLevel="3.6 Bloco de banners"
    />
  )
}

export const BannersBlock = (props: BannersBlockProps) => {
  const { subcategories = [] } = props

  const handleSendEvent = (title: string) => {
    const eventTrack = makeEventTrack({
      eventAction: title,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <div
      data-testid="section-banners"
      className="flex gap-2 overflow-x-auto scrollbar-hidden-v2 restructure-small-desktop:flex-wrap restructure-small-desktop:gap-4 restructure-small-desktop:overflow-x-hidden"
    >
      {subcategories.map((subcategory, index) => (
        <div
          data-testid="card-banner"
          key={index}
          className="relative w-[197px] min-w-[197px] restructure-small-desktop:w-[255px] restructure-small-desktop:min-w-[255px]"
        >
          <Link
            data-testid="link-banner"
            to={subcategory?.url ?? ''}
            className="block"
            target={subcategory.new_tab ? '_blank' : '_self'}
            rel="noreferrer"
            onClick={() => handleSendEvent(`${subcategory.title} - Menu`)}
          >
            <img
              data-testid="card-image"
              src={subcategory.banner}
              alt={subcategory.title}
              className="w-full"
            />
            <div
              data-testid="card-title"
              className="absolute bottom-2 left-2 restructure-tablet:bottom-4 restructure-tablet:left-4 text-restructure-primary-inverted mobile-heading-title5 tablet-body-regular-text2 desktop-body-regular-text1"
            >
              {subcategory.title}
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}
