import type { IconProps } from '../typesIcons'

export function PixPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Pix"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5632 16.2394C22.2552 16.2406 21.9501 16.1808 21.6654 16.0637C21.3807 15.9466 21.1221 15.7744 20.9045 15.557L18.5085 13.1674C18.4238 13.0866 18.311 13.0415 18.1938 13.0415C18.0765 13.0415 17.9638 13.0866 17.879 13.1674L15.475 15.5636C15.2576 15.7812 14.999 15.9538 14.7143 16.0713C14.4296 16.1887 14.1244 16.2488 13.8162 16.248H13.3472L16.3833 19.2742C16.8386 19.7271 17.4556 19.9815 18.0989 19.9815C18.7422 19.9815 19.3592 19.7271 19.8146 19.2742L22.8567 16.2415L22.5632 16.2394Z"
        fill="#32BCAD"
      />
      <path
        d="M13.8168 7.7942C14.1249 7.79338 14.4301 7.85344 14.7148 7.97092C14.9996 8.08839 15.2581 8.26095 15.4755 8.47862L17.8796 10.8754C17.9631 10.9584 18.0763 11.005 18.1943 11.005C18.3123 11.005 18.4255 10.9584 18.509 10.8754L20.9045 8.4877C21.1217 8.26976 21.3802 8.09699 21.665 7.97941C21.9497 7.86184 22.255 7.80179 22.5632 7.80277H22.8517L19.8095 4.77052C19.5842 4.5459 19.3168 4.36772 19.0224 4.24615C18.728 4.12458 18.4125 4.06201 18.0938 4.06201C17.7752 4.06201 17.4597 4.12458 17.1653 4.24615C16.8709 4.36772 16.6035 4.5459 16.3782 4.77052L13.3472 7.7942H13.8168Z"
        fill="#32BCAD"
      />
      <path
        d="M25.3681 10.3116L23.5297 8.47926C23.4883 8.49615 23.444 8.50505 23.3992 8.50549H22.5632C22.1282 8.50657 21.7112 8.67877 21.4029 8.98464L19.0075 11.3708C18.7917 11.5854 18.4994 11.706 18.1945 11.706C17.8897 11.706 17.5974 11.5854 17.3816 11.3708L14.977 8.97556C14.6688 8.6695 14.2518 8.49712 13.8167 8.49591H12.7905C12.7483 8.49496 12.7065 8.48659 12.6671 8.47119L10.8171 10.3116C10.3627 10.7655 10.1075 11.3805 10.1075 12.0217C10.1075 12.6629 10.3627 13.2779 10.8171 13.7317L12.663 15.5717C12.7023 15.556 12.7442 15.5476 12.7865 15.5469H13.8167C14.2518 15.5457 14.6688 15.3733 14.977 15.0673L17.3811 12.671C17.8158 12.2383 18.5733 12.2383 19.0075 12.671L21.4029 15.0582C21.7112 15.3641 22.1282 15.5363 22.5632 15.5374H23.3992C23.444 15.5377 23.4883 15.5466 23.5297 15.5636L25.3681 13.7312C25.5934 13.5067 25.7722 13.2401 25.8942 12.9467C26.0161 12.6533 26.0789 12.3388 26.0789 12.0212C26.0789 11.7036 26.0161 11.3891 25.8942 11.0957C25.7722 10.8022 25.5934 10.5357 25.3681 10.3111"
        fill="#32BCAD"
      />
    </svg>
  )
}
