import type { IconProps } from '../typesIcons'

export function AmexPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Cartão de crédito bandeira Amex"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01313 8.52783L3.74431 15.7616H7.65754L8.14267 14.6083H9.25156L9.73669 15.7616H14.0441V14.8813L14.4279 15.7616H16.656L17.0398 14.8627V15.7616H25.998L27.0872 14.6382L28.1072 15.7616L32.7083 15.7709L29.4291 12.1649L32.7083 8.52783H28.1785L27.1182 9.63049L26.1304 8.52783H16.3851L15.5482 10.3949L14.6918 8.52783H10.7867V9.37816L10.3523 8.52783H7.01313ZM20.3903 9.55504H25.5345L27.1079 11.2546L28.732 9.55504H30.3053L27.9148 12.1639L30.3053 14.7427H28.6606L27.0872 13.0234L25.4549 14.7427H20.3903V9.55504ZM21.6606 11.5773V10.6298V10.6289H24.8705L26.271 12.1443L24.8084 13.6679H21.6606V12.6335H24.467V11.5773H21.6606ZM7.77032 9.55504H9.67781L11.846 14.4603V9.55504H13.9356L15.6103 13.0721L17.1537 9.55504H19.2329V14.7458H17.9677L17.9574 10.6783L16.113 14.7458H14.9813L13.1266 10.6783V14.7458H10.5239L10.0305 13.5821H7.36483L6.87245 14.7447H5.47799L7.77032 9.55504ZM7.82 12.5063L8.69824 10.4333L9.57546 12.5063H7.82Z"
        fill="white"
      />
    </svg>
  )
}
