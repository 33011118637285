import type { ReactNode, FC } from 'react'
import { createContext, useContext } from 'react'

type ThemeContextData = {
  theme: string
  themes: Array<{
    key: string
    styles: ThemeStyle
  }>
}

type ThemeProps = {
  children: ReactNode
}

type ThemeStyle = {
  header: {
    background: string
    searchBackground: string
    searchText: string
    buttons: string
    minicartButton: string
    logoFill: string
  }
  homeBackground: string
  tipsCarousel: {
    background: string
    title: string
    text: string
    buttons: string
  }
  categories: {
    pictosStroke: string
    pictosTopBackground: string
    pictosBottomBackground: string
    pictosBottomText: string
  }
  banners: {
    buttonBackground: string
    buttonIcon: string
  }
  mostWanted: {
    heading: string
    chipsBackground: string
    chipsText: string
  }
  shelfs: {
    heading: string
    buttonsBackground: string
    buttonsIcon: string
    indicatorOn: string
    indicatorOff: string
  }
}

const ThemeContext = createContext({} as ThemeContextData)

const theme = 'default'

export const ThemeProvider: FC<ThemeProps> = ({ children }) => {
  const dcthl: ThemeStyle = {
    header: {
      background: 'bg-backgroundPrimary',
      searchBackground: 'bg-white',
      searchText: 'text-grayDark',
      buttons: 'text-white',
      minicartButton: 'bg-darkBlue',
      logoFill: '#0082C3',
    },
    homeBackground: 'bg-white',
    tipsCarousel: {
      background: 'bg-lighestGray',
      title: 'text-salesForceTitle',
      text: 'text-salesForceText',
      buttons: 'text-salesForceArrows',
    },
    categories: {
      pictosStroke: 'border-gray',
      pictosTopBackground: 'bg-mediumGray',
      pictosBottomBackground: 'bg-white',
      pictosBottomText: 'text-darkGray',
    },
    banners: {
      buttonBackground: 'bg-lighestGray',
      buttonIcon: 'text-blue',
    },
    mostWanted: {
      heading: 'text-deepGray',
      chipsBackground: 'bg-backgroundPrimary',
      chipsText: 'text-white',
    },
    shelfs: {
      heading: 'text-deepGray',
      buttonsBackground: 'bg-lighestGray',
      buttonsIcon: 'text-blue',
      indicatorOn: 'text-blue',
      indicatorOff: 'text-blue',
    },
  }

  const blueFriday: ThemeStyle = {
    header: {
      background: 'bg-gradient-to-b from-bfTopGradient to-bfBottomGradient',
      searchBackground: 'bg-neutral02',
      searchText: 'text-deepGray',
      buttons: 'text-white',
      minicartButton: 'transparent',
      logoFill: 'transparent',
    },
    homeBackground: 'bg-bfBackground',
    tipsCarousel: {
      background: 'bg-neutral08',
      title: 'text-white',
      text: 'text-white',
      buttons: 'text-neutral04 blueFridayArrows',
    },
    categories: {
      pictosStroke: 'border-lightGray',
      pictosTopBackground: 'bg-mediumGray',
      pictosBottomBackground: 'bg-bfBackground',
      pictosBottomText: 'text-white',
    },
    banners: {
      buttonBackground: 'bg-neutral10',
      buttonIcon: 'blueFridayArrows',
    },
    mostWanted: {
      heading: 'text-neutral02',
      chipsBackground: 'bg-backgroundPrimary',
      chipsText: 'text-neutral10',
    },
    shelfs: {
      heading: 'text-neutral02',
      buttonsBackground: 'bg-neutral10',
      buttonsIcon: 'blueFridayArrows',
      indicatorOn: 'text-lightestBlue',
      indicatorOff: '#3D9BCC',
    },
  }

  const ThemeProviderState = {
    theme,
    themes: [
      { key: 'default', styles: dcthl },
      { key: 'blueFriday', styles: blueFriday },
    ],
  }

  return (
    <ThemeContext.Provider value={ThemeProviderState}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
