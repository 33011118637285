import { useCallback, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import Section from 'src/components/sections/Section/Section'
import { readCookie, setCookie } from 'src/utils/getCookie'

const PopUpCookies = () => {
  const [showPopup, setShowPopup] = useState(false)
  const { pathname } = useLocation()

  const handleClosePopup = () => {
    setCookie('cookiePolicies', 'true', 365)
    setShowPopup(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const popupElement = document.querySelector('.popup-cookies')

    if (popupElement && !popupElement.contains(event.target as Node)) {
      setShowPopup(false)
    }
  }, [])

  useEffect(() => {
    if (!readCookie('cookiePolicies=true')) {
      setShowPopup(true)
    }
  }, [pathname])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  if (!showPopup) {
    return null
  }

  return (
    <Section className="bottom-6 popup-cookies fixed z-[998] pointer-events-none">
      <div className="layout__content pointer-events-none">
        <div className="max-w-md flex flex-col p-4 justify-end gap-6 bg-white rounded-lg pointer-events-auto">
          <div className="popup-cookies__text flex flex-col justify-center">
            <span
              role="img"
              aria-label="cookies"
              className="text-base font-bold"
            >
              🍪 Política de cookies
            </span>
            <p className="text-base pt-2">
              Os cookies nos ajudam a melhorar sua experiência online. Ao
              continuar navegando, você concorda com o uso de cookies. Saiba
              mais na nossa{' '}
              <a
                className="text-restructure-action"
                target="_blank"
                href="https://www.decathlon.com.br/servicos/politica-de-privacidade/"
                rel="noreferrer"
              >
                Política de privacidade
              </a>
              .
            </p>
            <button
              className="w-36 px-6 py-3.5 mt-6 bg-restructure-background-action-1 hover:bg-restructure-background-action-1-hover text-white rounded"
              onClick={handleClosePopup}
            >
              Aceitar
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PopUpCookies
