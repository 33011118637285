import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: 'https://82e0741a868049fd5560c89250ce7bec@o4506859562205184.ingest.us.sentry.io/4506859619155968',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend: (event, hint) => {
    if (hint?.originalException instanceof Error) {
      const error = hint.originalException
      if (
        error?.stack &&
        ['jquery-3.4.1.min.js', 'gtm.js'].some((scriptName) =>
          error.stack.includes(scriptName)
        )
      ) {
        return null
      }
    }
    return event
  },
  debug: process.env.NODE_ENV === 'production',
  enabled: true,
  tracesSampleRate: 0, //  Capture 0% of the transactions
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.5,
})
