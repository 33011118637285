import type { ReactNode, FC } from 'react'
import { useEffect, useState, createContext, useContext } from 'react'
import { getCookie } from 'src/utils/getCookie'

export type UserData = {
  Email: string
  FirstName: string
  LastName: string
  Gender: string
  IsPJ: boolean
  IsReturningUser: boolean
  IsUserDefined: boolean
  UserId: string
}

type UserDataContextData = {
  userData: UserData
  handleSetUserData: (value: UserData) => void
  userDataLoading: boolean
}

type ProductPageProps = {
  children: ReactNode
}

const UserDataContext = createContext({} as UserDataContextData)

export const UserDataProvider: FC<ProductPageProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>({} as UserData)
  const [userDataLoading, setUserDataLoading] = useState(true)

  const handleSetUserData = (value: UserData) => {
    setUserData(value)
    setUserDataLoading(false)
  }

  const loadFromLocalStorage = () => {
    const storageUserData = getCookie('currentUserData')

    const selectedUserData = storageUserData
      ? JSON.parse(storageUserData)
      : null

    if (!selectedUserData) {
      return
    }

    setUserData(selectedUserData)
    setUserDataLoading(false)
  }

  const UserDataProviderState = {
    userData,
    handleSetUserData,
    userDataLoading,
  }

  useEffect(() => {
    loadFromLocalStorage()
  }, [])

  return (
    <UserDataContext.Provider value={UserDataProviderState}>
      {children}
    </UserDataContext.Provider>
  )
}

export const useUserData = () => useContext(UserDataContext)
