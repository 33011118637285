type ButtonIconProp = {
  link: string
  children: React.ReactNode
  badgeValue: number
  onClick?: () => void
}

export function ButtonIcon({
  link,
  badgeValue,
  children,
  onClick,
}: ButtonIconProp) {
  const handleButtonClick = () => {
    window.location.href = link
  }

  const handleClick = onClick ?? handleButtonClick

  return (
    <button
      className="hover:bg-neutral02  hover:rounded-full focus:bg-neutral03 focus:rounded-full restructure-small-desktop:w-[48px] restructure-small-desktop:h-[48px] flex items-center justify-center relative p-2"
      onClick={handleClick}
    >
      {badgeValue > 0 && (
        <span className="absolute right-0 top-0 restructure-small-desktop:right-[4px] restructure-small-desktop:top-1 w-4 h-4 restructure-small-desktop:w-[20px] restructure-small-desktop:h-[20px] pl-[1px] flex items-center justify-center bg-restructure-background-action-1 mobile-overline-medium desktop-overline-medium text-restructure-primary-inverted rounded-full">
          {badgeValue}
        </span>
      )}
      {children}
    </button>
  )
}
