import axios from 'axios'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

const getAccessToken = async (refreshToken: string) => {
  return axios.post('/api/account/getAccessToken', {
    memberRefreshToken: refreshToken,
  })
}

const convertMinutesToDays = (minutes: number) => {
  return minutes / (24 * 60)
}

const validateAccessToken = (cookieAuthToken: string) => {
  if (!cookieAuthToken) {
    return null
  }

  const decodedAuthToken = jwt_decode(cookieAuthToken) as any

  const expiredToken = new Date(decodedAuthToken?.exp * 1000) < new Date()

  if (expiredToken) {
    Cookies.remove('memberAuthToken')

    return null
  }

  return cookieAuthToken
}

const validateRefreshToken = async (cookieRefreshToken: string) => {
  if (!cookieRefreshToken) {
    return null
  }

  try {
    const {
      data: { refreshTokenTemp },
    } = await getAccessToken(cookieRefreshToken)

    return refreshTokenTemp
  } catch {
    return null
  }
}

export const getAuthToken = async (): Promise<string | null> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const cookieAuthToken = Cookies.get('memberAuthToken') as string
    const cookieRefreshToken = Cookies.get('memberRefreshToken') as string
    const cookieRefreshTime = Cookies.get('memberRefreshTime') as string

    const accessToken = validateAccessToken(cookieAuthToken)

    if (!accessToken && cookieRefreshToken) {
      const { access_token: newAccessToken, refresh_token: newRefreshToken } =
        await validateRefreshToken(cookieRefreshToken)

      if (newAccessToken) {
        Cookies.set('memberAuthToken', newAccessToken, {
          path: '/',
          expires: convertMinutesToDays(15),
        })
        Cookies.set('memberRefreshToken', newRefreshToken, {
          path: '/',
          expires: new Date(cookieRefreshTime),
        })
      }

      return resolve(newAccessToken)
    }

    if (accessToken) {
      return resolve(accessToken)
    }
  })
}
