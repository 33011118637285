import { useMemo } from 'react'
import { VisaPaymentIcon } from 'src/components/Icons/PaymentIcons/Visa'
import { MastercardPaymentIcon } from 'src/components/Icons/PaymentIcons/Mastercard'
import { EloPaymentIcon } from 'src/components/Icons/PaymentIcons/Elo'
import { DinnersClubPaymentIcon } from 'src/components/Icons/PaymentIcons/DinnersClub'
import { AmexPaymentIcon } from 'src/components/Icons/PaymentIcons/Amex'
import { PaypalPaymentIcon } from 'src/components/Icons/PaymentIcons/Paypal'
import { PixPaymentIcon } from 'src/components/Icons/PaymentIcons/Pix'
import { BoletoPaymentIcon } from 'src/components/Icons/PaymentIcons/Boleto'
import { useMobile } from 'src/hooks/useMobile'

export const PaymentMethods = () => {
  const { isMobile } = useMobile()

  const checkWidthAndHeight = useMemo(() => {
    if (isMobile) {
      return {
        width: 52,
        height: 36,
      }
    }

    return {
      width: 34,
      height: 23,
    }
  }, [isMobile])

  return (
    <div
      aria-label="Listagem das formas de pagamento"
      className="flex flex-col text-restructure-primary-inverted desktop-overline-medium gap-2
    restructure-maxMobile:pt-3
    restructure-maxTablet:pt-3"
    >
      Formas de pagamento
      <div
        className="flex flex-row gap-2
  restructure-maxMobile:w-[328px]
  restructure-tablet:w-[328px]
  restructure-small-desktop:w-full
  flex-wrap"
      >
        <VisaPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <MastercardPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <EloPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <DinnersClubPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <AmexPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <PaypalPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <PixPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
        <BoletoPaymentIcon
          width={checkWidthAndHeight.width}
          height={checkWidthAndHeight.height}
        />
      </div>
    </div>
  )
}
