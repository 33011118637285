import type { IconProps } from '../typesIcons'

export function PaypalPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Paypal"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5694 15.2568H9.01657C8.91031 15.2568 8.81994 15.3341 8.80337 15.4389L8.17532 19.4209C8.16284 19.4995 8.22369 19.5703 8.30339 19.5703H9.04472C9.15099 19.5703 9.24136 19.493 9.25793 19.388L9.42732 18.314C9.44366 18.2088 9.53426 18.1317 9.6403 18.1317H10.1319C11.1548 18.1317 11.7451 17.6367 11.8993 16.6558C11.9688 16.2267 11.9023 15.8895 11.7013 15.6533C11.4806 15.3941 11.0891 15.2568 10.5694 15.2568ZM10.7486 16.7112C10.6637 17.2684 10.2379 17.2684 9.82626 17.2684H9.59193L9.75632 16.2277C9.76609 16.1649 9.82058 16.1186 9.88416 16.1186H9.99156C10.272 16.1186 10.5365 16.1186 10.6732 16.2784C10.7547 16.3738 10.7797 16.5154 10.7486 16.7112ZM15.2112 16.6933H14.4676C14.4042 16.6933 14.3495 16.7397 14.3397 16.8026L14.3068 17.0105L14.2548 16.9352C14.0938 16.7015 13.7349 16.6234 13.3766 16.6234C12.5548 16.6234 11.853 17.2458 11.7163 18.1189C11.6453 18.5543 11.7462 18.9707 11.9933 19.2611C12.2199 19.5281 12.5442 19.6394 12.9299 19.6394C13.592 19.6394 13.9592 19.2137 13.9592 19.2137L13.926 19.4203C13.9135 19.4994 13.9744 19.5702 14.0536 19.5702H14.7235C14.83 19.5702 14.9198 19.4929 14.9366 19.3879L15.3386 16.8427C15.3513 16.7645 15.2906 16.6933 15.2112 16.6933ZM14.1746 18.1406C14.1029 18.5654 13.7657 18.8506 13.3357 18.8506C13.1198 18.8506 12.9472 18.7814 12.8364 18.6501C12.7265 18.5198 12.6847 18.3343 12.7197 18.1277C12.7867 17.7065 13.1296 17.412 13.553 17.412C13.7641 17.412 13.9358 17.4821 14.0489 17.6145C14.1621 17.7483 14.2071 17.9349 14.1746 18.1406ZM18.4243 16.6932H19.1716C19.2763 16.6932 19.3373 16.8106 19.2778 16.8964L16.7925 20.4839C16.7522 20.542 16.6859 20.5766 16.6151 20.5766H15.8688C15.7637 20.5766 15.7023 20.4583 15.7632 20.3722L16.537 19.2798L15.714 16.8644C15.6855 16.7804 15.7475 16.6932 15.8368 16.6932H16.5711C16.6665 16.6932 16.7507 16.7558 16.7782 16.8471L17.215 18.306L18.2457 16.7879C18.286 16.7286 18.3531 16.6932 18.4243 16.6932Z"
        fill="white"
      />
      <path
        d="M26.5263 19.4206L27.1636 15.3658C27.1733 15.3029 27.2279 15.2566 27.2912 15.2563H28.0087C28.0879 15.2563 28.1487 15.3274 28.1362 15.406L27.5078 19.3877C27.4914 19.4928 27.401 19.57 27.2946 19.57H26.6538C26.5746 19.57 26.5138 19.4992 26.5263 19.4206ZM21.6456 15.2566H20.0925C19.9865 15.2566 19.8961 15.3338 19.8795 15.4387L19.2515 19.4206C19.239 19.4992 19.2998 19.57 19.3792 19.57H20.1761C20.2501 19.57 20.3135 19.516 20.3251 19.4424L20.5033 18.3137C20.5196 18.2085 20.6102 18.1314 20.7162 18.1314H21.2076C22.2307 18.1314 22.8209 17.6364 22.9753 16.6555C23.0449 16.2264 22.978 15.8892 22.7771 15.653C22.5565 15.3938 22.1653 15.2566 21.6456 15.2566ZM21.8248 16.7109C21.7401 17.2681 21.3143 17.2681 20.9024 17.2681H20.6683L20.833 16.2275C20.8428 16.1646 20.8968 16.1183 20.9605 16.1183H21.0679C21.3482 16.1183 21.613 16.1183 21.7496 16.2781C21.8311 16.3735 21.8559 16.5151 21.8248 16.7109ZM26.287 16.693H25.5439C25.48 16.693 25.4258 16.7394 25.4163 16.8023L25.3834 17.0103L25.3311 16.9349C25.1702 16.7012 24.8114 16.6232 24.4531 16.6232C23.6313 16.6232 22.9298 17.2455 22.7931 18.1186C22.7222 18.554 22.8228 18.9704 23.0698 19.2609C23.2969 19.5279 23.6207 19.6391 24.0065 19.6391C24.6685 19.6391 25.0357 19.2134 25.0357 19.2134L25.0026 19.4201C24.9901 19.4991 25.0509 19.5699 25.1307 19.5699H25.8002C25.9062 19.5699 25.9966 19.4927 26.0132 19.3876L26.4153 16.8425C26.4276 16.7642 26.3668 16.693 26.287 16.693ZM25.2506 18.1403C25.1792 18.5652 24.8416 18.8503 24.4115 18.8503C24.196 18.8503 24.0231 18.7811 23.9122 18.6499C23.8023 18.5196 23.761 18.334 23.7956 18.1274C23.8629 17.7062 24.2054 17.4118 24.6288 17.4118C24.84 17.4118 25.0116 17.4818 25.1248 17.6142C25.2385 17.748 25.2834 17.9346 25.2506 18.1403Z"
        fill="white"
      />
      <path
        d="M16.3161 13.832L16.507 12.6195L16.0818 12.6096H14.0512L15.4624 3.66179C15.4667 3.63478 15.481 3.60959 15.5018 3.5917C15.5226 3.57382 15.5493 3.56396 15.577 3.56396H19.0009C20.1376 3.56396 20.922 3.8005 21.3316 4.26736C21.5235 4.48638 21.6458 4.71524 21.705 4.96711C21.767 5.23139 21.7682 5.54713 21.7075 5.93223L21.7031 5.96034V6.20709L21.8952 6.31587C22.0569 6.40165 22.1853 6.49984 22.2839 6.61227C22.4482 6.79952 22.5544 7.03752 22.5993 7.31968C22.6456 7.60987 22.6303 7.95518 22.5544 8.34613C22.4668 8.79584 22.3252 9.1875 22.1339 9.50799C21.9579 9.8033 21.7338 10.0482 21.4677 10.238C21.2137 10.4184 20.9118 10.5553 20.5705 10.6429C20.2397 10.729 19.8627 10.7725 19.4491 10.7725H19.1827C18.9922 10.7725 18.807 10.8411 18.6618 10.9641C18.5162 11.0897 18.4197 11.2612 18.3902 11.4488L18.3701 11.558L18.0328 13.6951L18.0175 13.7736C18.0135 13.7985 18.0066 13.8109 17.9964 13.8193C17.9872 13.8269 17.9741 13.832 17.9613 13.832H16.3161Z"
        fill="#253B80"
      />
      <path
        d="M22.077 5.98877C22.0668 6.05411 22.0551 6.1209 22.042 6.18953C21.5904 8.50779 20.0457 9.30865 18.0727 9.30865H17.0681C16.8269 9.30865 16.6236 9.48385 16.586 9.72185L16.0716 12.9837L15.926 13.9083C15.9015 14.0645 16.022 14.2054 16.1796 14.2054H17.9614C18.1724 14.2054 18.3515 14.0521 18.3847 13.8441L18.4023 13.7535L18.7378 11.6247L18.7593 11.5079C18.7921 11.2992 18.9717 11.1459 19.1827 11.1459H19.4491C21.1753 11.1459 22.5267 10.445 22.9217 8.4169C23.0866 7.56968 23.0012 6.86227 22.5646 6.36474C22.4325 6.21471 22.2686 6.09024 22.077 5.98877Z"
        fill="#179BD7"
      />
      <path
        d="M21.6045 5.80074C21.5355 5.78066 21.4643 5.76241 21.3913 5.74599C21.3179 5.72993 21.2427 5.71569 21.1654 5.70328C20.8945 5.65948 20.5977 5.63867 20.2798 5.63867H17.5962C17.5301 5.63867 17.4673 5.65363 17.4111 5.68065C17.2873 5.74014 17.1954 5.85732 17.1731 6.00077L16.6021 9.61669L16.5858 9.72218C16.6234 9.48418 16.8267 9.30897 17.0679 9.30897H18.0725C20.0455 9.30897 21.5903 8.50775 22.0418 6.18985C22.0552 6.12123 22.0666 6.05443 22.0768 5.9891C21.9626 5.9285 21.8388 5.87667 21.7055 5.83249C21.6728 5.82154 21.6388 5.81096 21.6045 5.80074Z"
        fill="#222D65"
      />
      <path
        d="M17.1731 6.00074C17.1954 5.85728 17.2875 5.74011 17.4111 5.68097C17.4678 5.65396 17.5302 5.639 17.5962 5.639H20.2799C20.5979 5.639 20.8946 5.6598 21.1654 5.70361C21.2428 5.71602 21.318 5.73025 21.3914 5.74631C21.4644 5.76274 21.5356 5.781 21.6046 5.80106C21.6388 5.81129 21.6728 5.82187 21.706 5.83246C21.8392 5.87662 21.963 5.92883 22.0772 5.98906C22.2116 5.13235 22.0762 4.54904 21.613 4.02085C21.1023 3.43937 20.1806 3.19043 19.0012 3.19043H15.5773C15.3363 3.19043 15.1309 3.36563 15.0936 3.604L13.6675 12.6438C13.6394 12.8226 13.7773 12.984 13.9576 12.984H16.0715L16.6023 9.61665L17.1731 6.00074Z"
        fill="#253B80"
      />
    </svg>
  )
}
