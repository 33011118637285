import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'

import type { ProductDetailEvent } from './types/productDetailEvent'
import { makeProduct } from './makeProduct'

export const makeProductDetailEvent = (
  product: Product,
  productItem: ProductItem,
  eventTrigger: 'pdpView' | 'skuSelector'
): ProductDetailEvent => {
  const dimension24 = {
    pdpView: 'página de produto',
    skuSelector: 'página de produto - seletor',
  }

  return {
    event: 'productDetail',
    ecommerce: {
      detail: {
        products: [
          makeProduct(product, productItem, dimension24[eventTrigger]),
        ],
      },
      eventCallback: () => {
        document.location = `https://www.decathlon.com.br/${product.linkText}/p`
      },
    },
  }
}
