import axios from 'axios'

export const logoutUser = async ({
  memberAuthToken,
  pathname,
}: {
  memberAuthToken: string | null
  pathname: string
}) => {
  const { data: url } = await axios.post(
    `/api/account/logout?returnPath=${pathname}`,
    {
      memberAuthToken,
    }
  )

  window.sessionStorage.removeItem('user-decathlon')
  window.location.href = url
}
