import { Link } from 'gatsby'
import type { Level3SimpleListing } from 'src/graphql/queries/menu'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

type SimpleListingMobileProps = {
  subCategories: Level3SimpleListing[]
  department: string
  categorie?: string
  setMenuIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export function SimpleListingMobile({
  department,
  categorie,
  subCategories,
  setMenuIsOpen,
}: SimpleListingMobileProps) {
  const handleSendEvent = (event: string | undefined, position: string) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <>
      {subCategories?.map((item, index) => (
        <li className="mb-4" key={`${item.title}-${index}-mob`}>
          <Link
            className="block mobile-body-regular-text3 desktop-body-regular-text3 text-restructure-secondary"
            to={item.url ?? ''}
            aria-label={item.title ?? ''}
            target={item.new_tab ? '_blank' : '_top'}
            rel="noreferrer"
            onClick={() => {
              setMenuIsOpen?.(false)
              handleSendEvent(
                `${department} > ${categorie} > ${item.title}`,
                ' - Menu'
              )
            }}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </>
  )
}
