import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'
import { formatCategoriesForEvent } from 'src/utils/formatted'

import type { ViewItemEvent } from './types/viewItemEvent'

export const makeViewItemEvent = (
  product: Product,
  productItem: ProductItem
): ViewItemEvent => {
  const offer = productItem.sellerDefault.sellerOffer

  return {
    event: 'view_item',
    ecommerce: {
      currency: 'BRL',
      value: offer.price,
      items: [
        {
          arborescence: formatCategoriesForEvent(product.categories[0]),
          currency: 'BRL',
          discount: offer.discount,
          item_brand: product.brand,
          item_category2: product.sport,
          item_id: product.productId,
          item_name: product.productName,
          item_variant: productItem.itemId,
          item_variant_name: productItem.name,
          price: offer.price,
          product_reference_id: productItem.referenceId,
        },
      ],
    },
  }
}
