import type {
  PossibleThirdLevelLayout,
  SubcategoryBasedOnType,
} from 'src/graphql/queries/menu'

import { SimpleListing } from './SimpleListing'
import { BannersBlockContainer } from './BannersBlock/BannersBlock'

export interface DropdownContentVariations {
  typeThirtLevel: PossibleThirdLevelLayout
  subcategories?: SubcategoryBasedOnType[]
  department: string
  categorie?: string
  [key: string]: unknown
  setInHovering?: React.Dispatch<React.SetStateAction<boolean>>
  setMenuIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

function Blank() {
  return <div>Conteúdo não cadastrado como listagem simples </div>
}

const components = {
  '3.1 Bloco de marcas': Blank,
  '3.2 Editorial - liquidação': Blank,
  '3.3 Editorial - OPECO': Blank,
  '3.4 Grupo de esportes': Blank,
  '3.5 Listagem simples': SimpleListing,
  '3.6 Bloco de banners': BannersBlockContainer,
}

export default function DropdownContentVariations(
  props: DropdownContentVariations
) {
  const Component = components[props.typeThirtLevel]

  return Component ? <Component {...props} /> : null
}
