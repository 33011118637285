import { ChevronBlack } from './utils/icons'

export const ContentTitleNav = ({
  title,
  version,
  showIcon = true,
}: {
  title: string
  version?: string
  showIcon?: boolean
}) => {
  return (
    <>
      <span
        className={
          version === 'esportes em destaque'
            ? 'mobile-heading-title4 desktop-heading-title5 text-restructure-secondary mb-4 block'
            : 'mobile-body-regular-text3 desktop-body-regular-text3 text-restructure-secondary'
        }
      >
        {title}
      </span>
      {showIcon && (
        <div>
          <ChevronBlack />
        </div>
      )}
    </>
  )
}

export const ContentTitleNavFirstLevel = ({
  title,
}: {
  title: string
  version?: string
  showIcon?: boolean
}) => {
  return (
    <span
      className={`${
        title === 'Liquidação' || title === 'Outlet'
          ? 'text-restructure-error'
          : 'text-restructure-primary'
      } mobile-heading-title3 desktop-heading-title4`}
    >
      {title}
    </span>
  )
}
