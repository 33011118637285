import { Link } from 'gatsby'
import type { Level3SimpleListing } from 'src/graphql/queries/menu'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

type SimpleListingDesktopProps = {
  menuStyles: {
    [key: string]: string
  }
  titleId: string
  subCategories: Level3SimpleListing[]
  department: string
  setInHovering?: React.Dispatch<React.SetStateAction<boolean>>
}

export function SimpleListingDesktop({
  menuStyles,
  titleId,
  subCategories,
  department,
  setInHovering,
}: SimpleListingDesktopProps) {
  // MenuStyles.contentDropdown contém estilos definidos no container pai
  // Quandor tiver a flag `columns-to-count`, essa função é responsável por
  // aplicar via calculo qual deve ser a qtd de colunas para a propridade `columns` do css
  if (menuStyles.contentDropdown.includes('columns-to-count')) {
    const qtdMinItensPorColuna = 15

    const dynamicColumns = subCategories.length
      ? subCategories.length / qtdMinItensPorColuna <= 1
        ? 1
        : Math.trunc(subCategories.length / qtdMinItensPorColuna + 1)
      : 1

    menuStyles.contentDropdown = menuStyles.contentDropdown.replace(
      /columns-\d+/g,
      `columns-${dynamicColumns}`
    )
  }

  const handleSendEvent = (event: string | undefined, position: string) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <>
      <h2
        className={`desktop-body-semibold-text3 text-restructure-secondary mb-4 ${menuStyles.contentTitleDropdown}`}
      >
        {`${titleId}`}
      </h2>
      <ul className={`gap-6 ${menuStyles.contentDropdown}`}>
        {subCategories?.map((subCategory) => (
          <li
            key={subCategory.title}
            className={`text-left ${menuStyles?.contentItemDropdownList} first:mt-0`}
          >
            <Link
              to={subCategory.url ?? ''}
              className="desktop-body-regular-text3 text-restructure-secondary hover:text-restructure-action transition-all"
              aria-label={subCategory.title}
              title={subCategory.title}
              onClick={() => {
                handleSendEvent(
                  `${department} > ${titleId} > ${subCategory.title}`,
                  ' - Menu'
                )
                setInHovering?.(false)
              }}
            >
              {subCategory.title}
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}
