import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}
export function NewIconHeartOutline(props: Props) {
  return (
    <svg
      id="NewIconHeartOutline"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70528 1.81613C10.2109 1.60662 10.7529 1.49878 11.3002 1.49878C11.8476 1.49878 12.3896 1.60662 12.8952 1.81613C13.4009 2.02565 13.8603 2.33274 14.2472 2.71986C14.6343 3.10676 14.9413 3.56612 15.1508 4.0717C15.3603 4.57735 15.4681 5.11932 15.4681 5.66666C15.4681 6.214 15.3603 6.75598 15.1508 7.26163C14.9413 7.76724 14.6342 8.22663 14.2471 8.61355C14.2471 8.61358 14.2472 8.61352 14.2471 8.61355L8.3538 14.5069C8.15853 14.7021 7.84195 14.7021 7.64669 14.5069L1.75336 8.61355C0.971792 7.83199 0.532715 6.77196 0.532715 5.66666C0.532715 4.56137 0.971792 3.50134 1.75336 2.71978C2.53492 1.93821 3.59495 1.49914 4.70024 1.49914C5.80554 1.49914 6.86557 1.93821 7.64713 2.71978L8.00024 3.07289L8.35327 2.71986C8.74021 2.33274 9.19963 2.02565 9.70528 1.81613ZM11.3002 2.49878C10.8843 2.49878 10.4724 2.58074 10.0881 2.73997C9.70377 2.8992 9.35462 3.13259 9.06055 3.4268L8.3538 4.13355C8.15853 4.32881 7.84195 4.32881 7.64669 4.13355L6.94002 3.42688C6.346 2.83286 5.54032 2.49914 4.70024 2.49914C3.86016 2.49914 3.05449 2.83286 2.46046 3.42688C1.86644 4.02091 1.53271 4.82658 1.53271 5.66666C1.53271 6.50674 1.86644 7.31242 2.46046 7.90644L8.00024 13.4462L13.54 7.90644C13.8342 7.61237 14.0677 7.26313 14.2269 6.87884C14.3862 6.49454 14.4681 6.08264 14.4681 5.66666C14.4681 5.25069 14.3862 4.83878 14.2269 4.45449C14.0677 4.07019 13.8343 3.72104 13.5401 3.42697C13.246 3.13276 12.8967 2.8992 12.5124 2.73997C12.1281 2.58074 11.7162 2.49878 11.3002 2.49878Z"
        fill="#15181B"
      />
    </svg>
  )
}
