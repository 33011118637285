/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useEffect } from 'react'
import { countries } from 'src/components/common/Footer/Countries'
import Icon from 'src/components/ui/Icon'

import { countriesDictionary } from './CountriesDictionary'

function Dropdown() {
  const closeMenu = useRef<HTMLDivElement>(null)
  const [showOptions, setShowOptions] = useState<boolean>(false)

  const handleClickOutside = (event: { target: any }) => {
    if (closeMenu.current && !closeMenu.current.contains(event.target)) {
      setShowOptions(false)
    }
  }

  let userCity
  let userCountry
  let userTimeZone

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const tzArr = userTimeZone.split('/')

    userCity = tzArr[tzArr.length - 1]
    userCountry = countriesDictionary[userCity]
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showOptions])

  return (
    <div
      className="flex flex-row items-center justify-between w-full md:w-fit
    restructure-maxMobile:pt-12
    restructure-maxTablet:pt-12"
    >
      <div className="relative h-auto flex flex-1 items-center">
        <div
          className="flex flex-1 restructure-tablet:flex-none pb-[6px]"
          ref={closeMenu}
        >
          <button
            aria-label="Abrir menu para seleção de loja de outros países"
            onClick={() => setShowOptions(!showOptions)}
            type="button"
            className="inline-flex flex-1 justify-between mt-5 border px-4 py-2 items-center border-restructure-borderColor-primary
            desktop-body-regular-text3
           text-restructure-secondary-inverted
            relative rounded-[100px]
            h-[44px]
            restructure-small-desktop:w-[193px]
            restructure-tablet:w-[290px]"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            {userCountry}
            <Icon
              name="CaretDownDropdown"
              width={20}
              height={20}
              className={showOptions ? '-mr-1 ml-2 rotate-180' : '-mr-1 ml-2'}
            />
          </button>

          {showOptions && (
            <div
              className="origin-bottom top-full right-0 w-full shadow-lg focus:outline-none overflow-y-scroll max-h-[225px] absolute rounded-md bg-restructure-background-page scrollbar-v2"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              {countries.map((country, index) => (
                <div className="py-1" role="none" key={index}>
                  {!country.link ? (
                    <p
                      aria-label={`País: ${country.name}`}
                      className="cursor-pointer text-restructure-secondary block px-4 py-2
                      desktop-body-regular-text3"
                    >
                      {country.name}
                    </p>
                  ) : (
                    <a
                      aria-label={`Link de redirecionamento para a loja do país ${country.name}`}
                      href={country.link}
                      className="text-restructure-secondary block px-4 py-2
                        desktop-body-regular-text3
                        hover:bg-restructure-background-secondary"
                      role="menuitem"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {country.name}
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
