import type { IconProps } from '../typesIcons'

export function VisaPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Cartão de crédito bandeira Visa"
      width={width}
      height={height}
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6063 16.2722H8.48973L6.90259 10.2172C6.82726 9.93865 6.66731 9.69241 6.43202 9.57636C5.84484 9.28471 5.19781 9.0526 4.49195 8.93553V8.70241H7.90152C8.37209 8.70241 8.72501 9.0526 8.78384 9.45929L9.60733 13.827L11.7228 8.70241H13.7805L10.6063 16.2722ZM14.957 16.2722H12.9581L14.604 8.70241H16.6029L14.957 16.2722ZM19.189 10.7995C19.2478 10.3918 19.6007 10.1587 20.0125 10.1587C20.6595 10.1001 21.3643 10.2172 21.9526 10.5078L22.3055 8.87801C21.7173 8.6449 21.0702 8.52783 20.4831 8.52783C18.543 8.52783 17.1313 9.57636 17.1313 11.0316C17.1313 12.1386 18.1312 12.7199 18.8371 13.0701C19.6007 13.4193 19.8948 13.6524 19.836 14.0016C19.836 14.5253 19.2478 14.7585 18.6606 14.7585C17.9548 14.7585 17.2489 14.5839 16.6029 14.2922L16.25 15.923C16.9559 16.2137 17.7195 16.3307 18.4253 16.3307C20.6007 16.3883 21.9526 15.3407 21.9526 13.7685C21.9526 11.7885 19.189 11.6724 19.189 10.7995ZM28.9481 16.2722L27.361 8.70241H25.6562C25.3033 8.70241 24.9504 8.93553 24.8327 9.28471L21.8937 16.2722H23.9514L24.3622 15.1662H26.8904L27.1257 16.2722H28.9481ZM25.9503 10.7409L26.5375 13.5939H24.8916L25.9503 10.7409Z"
        fill="white"
      />
    </svg>
  )
}
