function getStatsigSessionId(): string {
  const cookieName = 'dkt_statsig_session'
  const expirationTimeInDays = 1

  let sessionId = getCookie(cookieName)

  if (!sessionId) {
    sessionId = randomUUID()
    setCookie(cookieName, sessionId, expirationTimeInDays)
  }

  return sessionId
}

function getCookie(name: string): string | null {
  const cookies = document.cookie.split(';')

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim())

    if (cookieName === name) {
      return cookieValue
    }
  }

  return null
}

function setCookie(name: string, value: string, daysToExpire: number): void {
  const expirationDate = new Date()
  const millisecondsPerDay = 24 * 60 * 60 * 1000

  expirationDate.setTime(
    expirationDate.getTime() + daysToExpire * millisecondsPerDay
  )

  const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`
  const cookieStringDomain = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/; domain=.decathlon.com.br`

  document.cookie = cookieString
  document.cookie = cookieStringDomain
}

const randomUUID = () =>
  typeof crypto.randomUUID === 'function'
    ? crypto.randomUUID()
    : (Math.random() * 1e6).toFixed(0)

export const statsigUser = { id: getStatsigSessionId() }
