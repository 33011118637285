import type { IconProps } from '../typesIcons'

export function MastercardPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Cartão de crédito bandeira Mastercard"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6557 17.1694C17.4662 18.1958 15.9233 18.8155 14.2374 18.8155C10.4754 18.8155 7.4258 15.7304 7.4258 11.9248C7.4258 8.11923 10.4754 5.03418 14.2374 5.03418C15.9233 5.03418 17.4662 5.65382 18.6557 6.68026C19.8451 5.65382 21.388 5.03418 23.074 5.03418C26.8359 5.03418 29.8856 8.11923 29.8856 11.9248C29.8856 15.7304 26.8359 18.8155 23.074 18.8155C21.388 18.8155 19.8451 18.1958 18.6557 17.1694Z"
        fill="#ED0006"
      />
      <path
        d="M18.6557 17.1694C20.1203 15.9055 21.0489 14.0249 21.0489 11.9248C21.0489 9.82476 20.1203 7.94413 18.6557 6.68026C19.8451 5.65382 21.388 5.03418 23.074 5.03418C26.8359 5.03418 29.8856 8.11923 29.8856 11.9248C29.8856 15.7304 26.8359 18.8155 23.074 18.8155C21.388 18.8155 19.8451 18.1958 18.6557 17.1694Z"
        fill="#F9A000"
      />
      <path
        d="M18.6557 6.68018C20.1203 7.94405 21.0489 9.82467 21.0489 11.9247C21.0489 14.0248 20.1203 15.9054 18.6557 17.1692C17.1911 15.9054 16.2625 14.0248 16.2625 11.9247C16.2625 9.82467 17.1911 7.94405 18.6557 6.68018Z"
        fill="#FF5E00"
      />
    </svg>
  )
}
