import type {
  BuyTogetherEventTrack,
  BuyTogetherEventTrackParams,
} from './types/buyTogetherEventTrack'

export const makeBuyTogetherEventTrack = ({
  eventAction,
  eventPage,
  product,
}: BuyTogetherEventTrackParams): BuyTogetherEventTrack => {
  return {
    event: 'event_track',
    event_category: 'clique',
    event_action: eventAction,
    event_label: eventPage,
    dimension22: product.items[0].itemId,
    // dimension23: product.items[0].manufactorerCode,
    id: product.productId,
  }
}
