interface Props {
  fill?: string
  size?: string
}

export const ArrowExternalLink = ({ fill = '#101010', size = '16' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8.385 2.053H3.596v-1.5h7.35v7.35h-1.5v-4.79l-7.441 7.441-1.06-1.06 7.44-7.441z"
        clipRule="evenodd"
      />
    </svg>
  )
}
