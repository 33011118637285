import type { IconProps } from '../typesIcons'

export function DinnersClubPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Cartão de crédito bandeira DinnersClub"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8232 19.5078C23.7138 19.5266 27.2649 16.3057 27.2649 12.3876C27.2649 8.10325 23.7138 5.14147 19.8232 5.14307H16.4748C12.5379 5.14147 9.29706 8.10405 9.29706 12.3876C9.29706 16.3065 12.5379 19.5266 16.4748 19.5078H19.8232Z"
        fill="#0079BE"
      />
      <path
        d="M16.4908 5.73682C12.8929 5.73802 9.97793 8.68061 9.97673 12.3125C9.97793 15.9439 12.8929 18.8865 16.4908 18.8877C20.0891 18.8865 23.0053 15.9439 23.0057 12.3125C23.0053 8.68061 20.0895 5.73802 16.4908 5.73682ZM12.362 12.3125C12.3612 11.4728 12.6138 10.6523 13.0867 9.95845C13.5596 9.26456 14.2309 8.72948 15.0127 8.42313V16.2014C14.2308 15.8953 13.5595 15.3603 13.0866 14.6665C12.6136 13.9726 12.3611 13.1522 12.362 12.3125ZM17.9685 16.203V8.42273C18.7509 8.72864 19.4228 9.26368 19.896 9.9578C20.3693 10.6519 20.6219 11.4728 20.6208 12.3129C20.6217 13.1529 20.369 13.9737 19.8958 14.6678C19.4226 15.3619 18.7508 15.897 17.9685 16.203Z"
        fill="white"
      />
      <path
        d="M19.8232 19.5078C23.7138 19.5266 27.2649 16.3057 27.2649 12.3876C27.2649 8.10325 23.7138 5.14147 19.8232 5.14307H16.4748C12.5379 5.14147 9.29706 8.10405 9.29706 12.3876C9.29706 16.3065 12.5379 19.5266 16.4748 19.5078H19.8232Z"
        fill="#0079BE"
      />
      <path
        d="M16.4908 5.73682C12.8929 5.73802 9.97793 8.68061 9.97673 12.3125C9.97793 15.9439 12.8929 18.8865 16.4908 18.8877C20.0891 18.8865 23.0053 15.9439 23.0057 12.3125C23.0053 8.68061 20.0895 5.73802 16.4908 5.73682ZM12.362 12.3125C12.3612 11.4728 12.6138 10.6523 13.0867 9.95845C13.5596 9.26456 14.2309 8.72948 15.0127 8.42313V16.2014C14.2308 15.8953 13.5595 15.3603 13.0866 14.6665C12.6136 13.9726 12.3611 13.1522 12.362 12.3125ZM17.9685 16.203V8.42273C18.7509 8.72864 19.4228 9.26368 19.896 9.9578C20.3693 10.6519 20.6219 11.4728 20.6208 12.3129C20.6217 13.1529 20.369 13.9737 19.8958 14.6678C19.4226 15.3619 18.7508 15.897 17.9685 16.203Z"
        fill="white"
      />
    </svg>
  )
}
