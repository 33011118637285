import type { Level3SimpleListing } from 'src/graphql/queries/menu'

import type { DropdownContentVariations } from '..'
import { SimpleListingDesktop } from './SimpleListingDesktop'
import { SimpleListingMobile } from './SimpleListingMobile'

interface SimpleListForce {
  menuStyles: {
    [key: string]: string
  }
  titleId: string
  subcategories: Level3SimpleListing[]
  isMobile?: boolean
  department: string
  categorie: string
  setInHovering?: React.Dispatch<React.SetStateAction<boolean>>
  setMenuIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export function SimpleListing(props: DropdownContentVariations) {
  const {
    department,
    categorie,
    titleId,
    isMobile,
    menuStyles,
    subcategories = [],
    setInHovering,
    setMenuIsOpen,
  } = props as unknown as SimpleListForce

  return (
    <>
      {isMobile ? (
        <SimpleListingMobile
          department={department}
          categorie={categorie}
          subCategories={subcategories}
          setMenuIsOpen={setMenuIsOpen}
        />
      ) : (
        <SimpleListingDesktop
          department={department}
          titleId={titleId}
          menuStyles={menuStyles}
          subCategories={subcategories}
          setInHovering={setInHovering}
        />
      )}
    </>
  )
}
