import type { IconProps } from '../typesIcons'

export function BoletoPaymentIcon({ width = 52, height = 36 }: IconProps) {
  return (
    <svg
      aria-label="Boleto bancário"
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05004 18.3623V14.8623H10.47C11.135 14.8623 11.625 15.1223 11.625 15.7423C11.625 16.1523 11.33 16.4123 10.99 16.5123C11.43 16.6223 11.72 16.9173 11.72 17.3673C11.72 18.1273 11.065 18.3623 10.37 18.3623H9.05004ZM9.60004 17.8923H10.42C10.92 17.8923 11.15 17.6973 11.15 17.3273C11.15 16.9723 10.95 16.7673 10.41 16.7673H9.60004V17.8923ZM9.60004 16.3023H10.4C10.835 16.3023 11.055 16.1173 11.055 15.8023C11.055 15.5173 10.87 15.3273 10.415 15.3273H9.60004V16.3023ZM13.7406 18.4423C12.7806 18.4423 12.0556 17.7473 12.0556 16.6123C12.0556 15.4973 12.7806 14.7773 13.7406 14.7773C14.7056 14.7773 15.4356 15.5073 15.4356 16.6123C15.4356 17.7473 14.7006 18.4423 13.7406 18.4423ZM12.6306 16.6123C12.6306 17.4673 13.0756 17.9773 13.7406 17.9773C14.4056 17.9773 14.8606 17.4723 14.8606 16.6123C14.8606 15.7723 14.4056 15.2473 13.7406 15.2473C13.0806 15.2473 12.6306 15.7673 12.6306 16.6123ZM15.9692 18.3623V14.8623H16.5242V17.8923H18.3142V18.3623H15.9692ZM18.6768 18.3623V14.8623H21.0318V15.3273H19.2318V16.3273H20.9518V16.8023H19.2318V17.8923H21.0968V18.3623H18.6768ZM22.463 18.3623V15.3273H21.273V14.8623H24.203V15.3273H23.018V18.3623H22.463ZM25.8894 18.4423C24.9294 18.4423 24.2044 17.7473 24.2044 16.6123C24.2044 15.4973 24.9294 14.7773 25.8894 14.7773C26.8544 14.7773 27.5844 15.5073 27.5844 16.6123C27.5844 17.7473 26.8494 18.4423 25.8894 18.4423ZM24.7794 16.6123C24.7794 17.4673 25.2244 17.9773 25.8894 17.9773C26.5544 17.9773 27.0094 17.4723 27.0094 16.6123C27.0094 15.7723 26.5544 15.2473 25.8894 15.2473C25.2294 15.2473 24.7794 15.7673 24.7794 16.6123Z"
        fill="white"
      />
      <rect x="8.63004" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="9.03629" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="9.61441" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="12.7417" y="6.8623" width="0.6" height="5.5" fill="#D9D9D9" />
      <rect x="12.1357" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="13.9325" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="14.8544" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="16.3232" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="21.1982" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="21.9794" y="6.8623" width="0.4" height="5.5" fill="#D9D9D9" />
      <rect x="23.12" y="6.8623" width="0.6" height="5.5" fill="#D9D9D9" />
      <rect x="24.37" y="6.8623" width="0.6" height="5.5" fill="#D9D9D9" />
      <rect x="20.4171" y="6.8623" width="0.6" height="5.5" fill="#D9D9D9" />
      <rect x="10.3175" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="14.5386" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="15.9292" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="17.273" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="17.5889" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="17.9306" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="18.3926" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="18.7085" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="19.8179" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="22.4954" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="25.3548" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="26.0767" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="26.6548" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="27.1167" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="11.7296" y="6.8623" width="0.2" height="5.5" fill="#D9D9D9" />
      <rect x="10.7235" y="6.8623" width="0.8" height="5.5" fill="#D9D9D9" />
    </svg>
  )
}
