import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'
import { useLinxProductClick } from 'src/sdk/linx/hooks/useLinxProductClick'
import { formatterPrice } from 'src/utils/formatterPrice'
import { stripUrl } from 'src/utils/stripUrl'

type Props = {
  imageUrl: string
  slug: string
  name: string
  price?: number
  listPrice?: number
  clickUrl: string
  onClick?: () => void
}

export function CardItem({
  imageUrl,
  name,
  slug,
  listPrice,
  price,
  clickUrl,
  onClick,
}: Props) {
  const { sendProductClickEvent } = useLinxProductClick()

  return (
    <Link
      to={slug}
      className="flex items-start gap-md"
      onClick={() => {
        sendProductClickEvent({ clickUrl })
        onClick?.()
      }}
    >
      <Image
        src={stripUrl(imageUrl)}
        width={80}
        height={80}
        alt={name}
        className="rounded-md"
      />
      <div>
        <p className="w-full desktop-body-regular-text2 text-restructure-primary line-clamp-2">
          {name}
        </p>

        <div className="mt-xs flex items-center gap-xs">
          {price && (
            <p className="desktop-body-regular-text2 text-restructure-secondary">
              {formatterPrice(price)}
            </p>
          )}{' '}
          {listPrice && price && listPrice > price && (
            <p className="desktop-body-regular-text2 text-restructure-error line-through">
              {formatterPrice(listPrice)}
            </p>
          )}
        </div>
      </div>
    </Link>
  )
}
