const countries = [
  {
    name: 'Europa',
    link: '',
  },
  {
    name: 'Alemanha',
    link: 'https://www.decathlon.de/',
  },
  {
    name: 'Áustria',
    link: 'https://www.decathlon.at/',
  },
  {
    name: 'Bélgica',
    link: 'https://www.decathlon.be/fr/',
  },
  {
    name: 'Bulgária',
    link: 'https://www.decathlon.bg/',
  },
  {
    name: 'Croácia',
    link: 'https://www.decathlon.hr/',
  },
  {
    name: 'Eslováquia',
    link: 'https://www.decathlon.sk/',
  },
  {
    name: 'Eslovênia',
    link: 'https://www.decathlon.si/',
  },
  {
    name: 'Espanha',
    link: 'https://www.decathlon.es/es/',
  },
  {
    name: 'França',
    link: 'https://www.decathlon.fr/',
  },
  {
    name: 'Grécia',
    link: 'https://www.decathlon.com.gr/el/',
  },
  {
    name: 'Hungria',
    link: 'https://www.decathlon.hu/',
  },
  {
    name: 'Irlanda',
    link: 'https://www.decathlon.ie/',
  },
  {
    name: 'Israel',
    link: 'https://www.decathlon.co.il/iw/',
  },
  {
    name: 'Itália',
    link: 'https://www.decathlon.it/',
  },
  {
    name: 'Letônia',
    link: 'https://decathlon.lv/en/',
  },
  {
    name: 'Lituânia',
    link: 'https://www.decathlon.lt/lt/',
  },
  {
    name: 'Malta',
    link: 'https://www.decathlon.mt/',
  },
  {
    name: 'Países Baixos',
    link: 'https://www.decathlon.nl/',
  },
  {
    name: 'Pôlonia',
    link: 'https://www.decathlon.pl/',
  },
  {
    name: 'Portugal',
    link: 'https://www.decathlon.pt/',
  },
  {
    name: 'Reino Unido',
    link: 'https://www.decathlon.co.uk/',
  },
  {
    name: 'República Checa',
    link: 'https://www.decathlon.cz/',
  },
  {
    name: 'Romênia',
    link: 'https://www.decathlon.ro/',
  },
  {
    name: 'Russia',
    link: 'https://www.decathlon.ru/',
  },
  {
    name: 'Sérvia',
    link: 'https://www.decathlon.rs/',
  },
  {
    name: 'Suécia',
    link: 'https://www.decathlon.se/',
  },
  {
    name: 'Suíça',
    link: 'https://www.decathlon.ch/de/splashpage/',
  },
  {
    name: 'Turquia',
    link: 'https://www.decathlon.com.tr/',
  },
  {
    name: 'Ucrânia',
    link: 'https://www.decathlon.ua/uk/',
  },
  {
    name: 'Ásia',
    link: '',
  },
  {
    name: 'Cambodja',
    link: 'https://www.decathlon.com.kh/en/',
  },
  {
    name: 'Cazaquistão',
    link: 'https://www.decathlonkz.com/',
  },
  {
    name: 'China',
    link: 'https://www.decathlon.com.cn/en/',
  },
  {
    name: 'Coreia do Sul',
    link: 'https://www.decathlon.co.kr/kr_en/',
  },
  {
    name: 'Filipinas',
    link: 'https://www.decathlon.ph/',
  },
  {
    name: 'Índia',
    link: 'https://www.decathlon.in/',
  },
  {
    name: 'Indonésia',
    link: 'https://www.decathlon.co.id/in/',
  },
  {
    name: 'Japão',
    link: 'https://www.decathlon.co.jp/',
  },
  {
    name: 'Malásia',
    link: 'https://www.decathlon.my/',
  },
  {
    name: 'Singapura',
    link: 'https://www.decathlon.sg/',
  },
  {
    name: 'Sri Lanka',
    link: 'https://decathlon.lk/',
  },
  {
    name: 'Tailândia',
    link: 'https://www.decathlon.co.th/en',
  },
  {
    name: 'Taiwan',
    link: 'https://www.decathlon.tw/zh',
  },
  {
    name: 'Vietnam',
    link: 'https://www.decathlon.vn/',
  },
  {
    name: 'Américas',
    link: '',
  },
  {
    name: 'Brasil',
    link: '/',
  },
  {
    name: 'Canadá',
    link: 'https://www.decathlon.ca/en/',
  },
  {
    name: 'Chile',
    link: 'https://www.decathlon.cl/',
  },
  {
    name: 'Colômbia',
    link: 'https://www.decathlon.com.co/',
  },
  {
    name: 'Estados Unidos',
    link: 'https://www.decathlon.com/',
  },
  {
    name: 'México',
    link: 'https://www.decathlon.com.mx/',
  },
  {
    name: 'África',
    link: '',
  },
  {
    name: 'África do Sul',
    link: 'https://www.decathlon.co.za/',
  },
  {
    name: 'Argélia',
    link: 'https://www.decathlon.com.dz/',
  },
  {
    name: 'Costa do Marfim',
    link: 'https://www.decathlon.ci/',
  },
  {
    name: 'Egito',
    link: 'https://www.decathlon.eg/ar/',
  },
  {
    name: 'Gana',
    link: 'https://www.decathlon.com.gh/',
  },
  {
    name: 'Marrocos',
    link: 'https://www.decathlon.ma/',
  },
  {
    name: 'Quénia',
    link: 'https://www.decathlon.co.ke/',
  },
  {
    name: 'República do Congo',
    link: 'https://www.decathlon-rdc.com/',
  },
  {
    name: 'Senegal',
    link: 'https://decathlon.sn/',
  },
  {
    name: 'Tunísia',
    link: 'https://www.decathlon.tn/',
  },
  {
    name: 'Oceânia',
    link: '',
  },
  {
    name: 'Austrália',
    link: 'https://decathlon.com.au/',
  },
  {
    name: 'Luxemburgo',
    link: 'https://www.decathlon.be/static/lux/startpage/',
  },
]

export { countries }
