import type { CSSProperties } from 'react'

type CloseButtonProps = {
  size: number
  thickness: number
  color: string
  left?: string
  top?: string
}

const CloseButton = ({
  size,
  thickness,
  color,
  left = '50%',
  top = '50%',
}: CloseButtonProps) => {
  const styleBefore: CSSProperties = {
    display: 'block',
    position: 'absolute',
    left: `${left}`,
    top: `${top}`,
    width: `${size}px`,
    height: `${thickness}px`,
    backgroundColor: `${color}`,
    transform: 'translate(-50%, -50%) rotate(45deg)',
  }

  const styleAfter: CSSProperties = {
    display: 'block',
    position: 'absolute',
    left: `${left}`,
    top: `${top}`,
    width: `${size}px`,
    height: `${thickness}px`,
    backgroundColor: `${color}`,
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  }

  return (
    <>
      <span style={styleBefore} />
      <span style={styleAfter} />
    </>
  )
}

export default CloseButton
