import { useFooter } from './useFooter'

type SubHeaderProps = {
  title: string
  link: string
}

export const SubHeader: React.FC<SubHeaderProps> = ({ title, link }) => {
  const { sendEventRedirect } = useFooter()

  return (
    <a
      data-testid="footerSubHeader"
      onClick={() => sendEventRedirect(title)}
      aria-label={`Link de redirecionamento para tela ${title}`}
      href={link}
      className="text-restructure-white
mobile-heading-title4
tablet-mobile-heading-title4
desktop-heading-title4"
    >
      {title}
    </a>
  )
}
