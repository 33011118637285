import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'

import type { CheckoutShippingEvent } from './types/checkoutShippingEvent'
import { makeProduct } from './makeProduct'

export const makeCheckoutShippingEvent = (
  product: Product,
  productItem: ProductItem
): CheckoutShippingEvent => {
  return {
    event: 'checkoutShipping',
    ecommerce: {
      detail: {
        action: 'product page',
      },
      products: [makeProduct(product, productItem)],
      eventCallback: () => {
        document.location = `https://www.decathlon.com.br/${product.linkText}/p`
      },
    },
  }
}
