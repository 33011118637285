import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}
export function LopOutline({ color = '#3E4751', ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74251 2.5C5.07314 2.5 2.90918 4.66396 2.90918 7.33333C2.90918 10.0027 5.07314 12.1667 7.74251 12.1667C10.4119 12.1667 12.5758 10.0027 12.5758 7.33333C12.5758 4.66396 10.4119 2.5 7.74251 2.5ZM1.90918 7.33333C1.90918 4.11167 4.52085 1.5 7.74251 1.5C10.9642 1.5 13.5758 4.11167 13.5758 7.33333C13.5758 10.555 10.9642 13.1667 7.74251 13.1667C4.52085 13.1667 1.90918 10.555 1.90918 7.33333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1557 10.7465C11.351 10.5512 11.6676 10.5512 11.8628 10.7465L14.7628 13.6465C14.9581 13.8417 14.9581 14.1583 14.7628 14.3536C14.5676 14.5488 14.251 14.5488 14.0557 14.3536L11.1557 11.4536C10.9605 11.2583 10.9605 10.9417 11.1557 10.7465Z"
        fill={color}
      />
    </svg>
  )
}
