import { useState } from 'react'
import { useMenu } from 'src/graphql/queries/menu'
import { useLocation } from '@reach/router'
import Logo from 'src/components/ui/Logo'

import { NavItem } from './NavItem'
import { IconCloseMenu } from './components/utils/icons'
import MyAccountSubMenu from '../MyAccountSubMenu'
import NavigationAccount from './NavigationAccount'

interface NavigationProps {
  readonly setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function Navigation({ setMenuIsOpen }: NavigationProps) {
  const location = useLocation()
  const [isMyAccountSubMenuOpen, setIsMyAccountSubMenuOpen] = useState(
    location.pathname.includes('/account')
  )

  const [delayLeave, setDelayLeave] = useState(false)

  function handleMyAccountSubMenu(state: boolean) {
    setIsMyAccountSubMenuOpen(state)

    if (state === false) {
      setTimeout(() => {
        setDelayLeave(false)
      }, 2000)
    } else {
      setDelayLeave(true)
    }
  }

  const {
    allCmsHome: {
      nodes: [{ sections: sectionsData }],
    },
  } = useMenu()

  const { departments } = sectionsData[0].data

  return (
    <nav className="w-full px-4 py-7">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-7">
          <Logo width={186} height={28} bgFill="#3643BA" />
          <button onClick={() => setMenuIsOpen(false)}>
            <IconCloseMenu />
          </button>
        </div>

        <NavigationAccount
          handleMyAccountSubMenu={(state) => handleMyAccountSubMenu(state)}
        />

        <ul className="flex flex-col overflow-hidden">
          {departments?.map((item, index) => (
            <NavItem
              key={`${item.title}-${index}-mob`}
              item={item}
              setMenuIsOpen={setMenuIsOpen}
            />
          ))}
        </ul>

        {(isMyAccountSubMenuOpen || delayLeave) && (
          <MyAccountSubMenu
            {...{
              handleMyAccountSubMenu,
              setMenuIsOpen,
              isMyAccountSubMenuOpen,
            }}
          />
        )}
      </div>
    </nav>
  )
}

export default Navigation
