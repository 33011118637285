import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}
export function BagCartOutline(props: Props) {
  return (
    <svg
      id="BagCartOutline"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 1.03333C3.69443 0.907425 3.84262 0.833328 4 0.833328H12C12.1574 0.833328 12.3056 0.907425 12.4 1.03333L14.4 3.7C14.4649 3.78654 14.5 3.89181 14.5 3.99999V13.3333C14.5 13.8196 14.3068 14.2859 13.963 14.6297C13.6192 14.9735 13.1529 15.1667 12.6667 15.1667H3.33333C2.8471 15.1667 2.38079 14.9735 2.03697 14.6297C1.69315 14.2859 1.5 13.8196 1.5 13.3333V3.99999C1.5 3.89181 1.53509 3.78654 1.6 3.7L3.6 1.03333ZM4.25 1.83333L2.5 4.16666V13.3333C2.5 13.5543 2.5878 13.7663 2.74408 13.9226C2.90036 14.0789 3.11232 14.1667 3.33333 14.1667H12.6667C12.8877 14.1667 13.0996 14.0789 13.2559 13.9226C13.4122 13.7663 13.5 13.5543 13.5 13.3333V4.16666L11.75 1.83333H4.25Z"
        fill={props.color ?? '#15181B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4C1.5 3.72386 1.72386 3.5 2 3.5H14C14.2761 3.5 14.5 3.72386 14.5 4C14.5 4.27614 14.2761 4.5 14 4.5H2C1.72386 4.5 1.5 4.27614 1.5 4Z"
        fill={props.color ?? '#15181B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3335 6.16667C5.60964 6.16667 5.8335 6.39053 5.8335 6.66667C5.8335 7.24131 6.06177 7.79241 6.4681 8.19874C6.87443 8.60507 7.42553 8.83334 8.00016 8.83334C8.5748 8.83334 9.1259 8.60507 9.53223 8.19874C9.93856 7.79241 10.1668 7.24131 10.1668 6.66667C10.1668 6.39053 10.3907 6.16667 10.6668 6.16667C10.943 6.16667 11.1668 6.39053 11.1668 6.66667C11.1668 7.50652 10.8332 8.31198 10.2393 8.90584C9.64547 9.49971 8.84001 9.83334 8.00016 9.83334C7.16031 9.83334 6.35486 9.49971 5.76099 8.90584C5.16713 8.31198 4.8335 7.50652 4.8335 6.66667C4.8335 6.39053 5.05735 6.16667 5.3335 6.16667Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  )
}
