import { useCallback } from 'react'

import type { LinxMeta } from '../types'
import { useLinxUser } from './useLinxUser'

export const useLinxEventDispatch = () => {
  const { addUserIfLogged } = useLinxUser()

  /**
   * Fills the linxMeta with information about the page viewed and fires the navigate event.
   * Obs: the event can only be triggered after loading the metadata.js script
   */
  const dispatchLinxEvent = useCallback(
    (linxMeta: LinxMeta) => {
      const linxMetaWithUser = addUserIfLogged(linxMeta)

      window.linxMeta = linxMetaWithUser

      const interval = setInterval(doDispatch, 750)

      function doDispatch() {
        const scriptMetadataExist = document.querySelector(
          'script[src$="metadata.js"]'
        )

        if (scriptMetadataExist) {
          window?.linxImpulse?.createEvent('linximpulse.navigation')

          clearInterval(interval)
        }
      }
    },
    [addUserIfLogged]
  )

  return { dispatchLinxEvent }
}
