import {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import type { ReactNode } from 'react'
import axios from 'axios'

export type CepResponseProps = {
  postalCode: string
  city: string
  state: string
  country: string
  street: string
  neighborhood: string
}

type CepContextData = {
  addressStorage: CepResponseProps | null
  cepResult: CepResponseProps | null
  cepStorage: string | null
  setCepResult: (result: CepResponseProps | null) => void
  setCepStorage: (storage: string | null) => void
  isValidating: boolean
  setIsValidating: (valid: boolean) => void
  getCEP: (cep: string) => Promise<CepResponseProps | null>
}

type CepProviderProps = {
  children: ReactNode
}

type SessionStorageObject = {
  getAddresses: Array<{
    id: string
    accuracy: string
    administrative_divisions: {
      district: string
      province: string
    }
    complement: string
    country_code: string | null
    firstname: string | null
    lastname: string | null
    is_favorite: boolean
    locality: string
    phone_number: string
    postal_code: string
    street: string
    title: string | null
    type: string
    vtexId: string
  }>
  getConsent: {
    events: Array<{
      organization_user_id: string
      created_at: string
      updated_at: string | null
      version: number
      consents: {
        channels: unknown[]
        purposes: Array<{
          id: string
          enabled: boolean
        }>
      }
    }>
  }
  getFavoriteStore: {
    third_type: string
    third_num: string
    third_sub_num: string
  }
  getPurchase: unknown[]
  getSportsPractice: unknown[]
  getUserProfile: {
    additional_information: Array<{
      id: string
      value: string
    }>
    claims: {
      given_name: string
      middle_name: string | null
      family_name: string | null
      birthdate: string | null
      gender: string
      phone_number: string
      email: string
      phone_verified: boolean
    }
    identifiers: Array<{
      id: string
      value: string
    }>
  }
}

export const CepContext = createContext<CepContextData>({} as CepContextData)

const checkHyphenInCEP = (cep: string) => {
  if (cep.includes('-')) {
    return cep
  }

  return `${cep.slice(0, 5)}-${cep.slice(5)}`
}

export const CepInformationProvider = ({ children }: CepProviderProps) => {
  useEffect(() => {
    if (localStorage.getItem('cep')) {
      setCepStorage(String(localStorage.getItem('cep')))

      return
    }

    const sessionStorageObject: SessionStorageObject = JSON.parse(
      sessionStorage.getItem('user-decathlon') as string
    )

    const favoriteAddress = sessionStorageObject?.getAddresses?.filter(
      (address) => address.is_favorite
    )

    if (favoriteAddress?.[0]?.postal_code) {
      setCepStorage(checkHyphenInCEP(favoriteAddress[0].postal_code))

      return
    }

    if (sessionStorageObject?.getAddresses?.[0]?.postal_code) {
      setCepStorage(
        checkHyphenInCEP(sessionStorageObject?.getAddresses[0].postal_code)
      )
    }
  }, [])

  const [cepStorage, setCepStorage] = useState<string | null>(null)

  const [isValidating, setIsValidating] = useState(false)
  const [cepResult, setCepResult] = useState<CepResponseProps | null>(null)
  const [addressStorage, setAddressStorage] = useState<CepResponseProps | null>(
    null
  )

  const getCEP = useCallback(
    async (cep: string): Promise<CepResponseProps | null> => {
      setIsValidating(true)
      const url = `/api/getAddressByPostalCode?cep=${cep}`

      try {
        const { data }: { data: CepResponseProps } = await axios.get(url)

        setAddressStorage(data)
        setCepResult(data)
        setIsValidating(false)

        return data
      } catch {
        setCepResult(null)
        setAddressStorage(null)
      }

      setIsValidating(false)

      return null
    },
    []
  )

  useEffect(() => {
    if (!cepStorage) {
      return
    }

    getCEP(cepStorage)
  }, [cepStorage, getCEP])

  return (
    <CepContext.Provider
      value={{
        cepResult,
        cepStorage,
        setCepResult,
        setCepStorage,
        isValidating,
        setIsValidating,
        getCEP,
        addressStorage,
      }}
    >
      {children}
    </CepContext.Provider>
  )
}

export const useCepStorage = () => useContext(CepContext)
